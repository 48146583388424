import React, { useContext } from 'react';

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@swyftx/aviary/atoms/Accordion/Accordion';
import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Chat, Heart, Help, Learn, LogOut, WhatsNew } from '@swyftx/aviary/icons/outlined';
import { AppSideBarItem } from '@swyftx/aviary/molecules/AppSideBar/AppSideBarItem';

import env from '@shared/config';
import { Links } from '@shared/constants';
import { UIStore } from '@shared/store';

import { useLogout } from '@hooks/Logout';

import { observer } from 'mobx-react-lite';
import { useIntercom } from 'react-use-intercom';
import { useUsersnapApi } from 'src/context/UserSnap/UserSnap.context';
import { FeatureDiscoveryContext } from 'src/lib/feature-discovery/context/FeatureDiscovery.context';

const SideBarMobileFooter: React.FC = observer(() => {
  const { setNavDrawerExpanded } = UIStore.useUIStore;
  const { setShowAll: openWhatsNew } = useContext(FeatureDiscoveryContext);

  const usersnapApi = useUsersnapApi();
  const feedbackID = env.USERSNAP_FEEDBACK_PROJECT_ID;

  const { show: openLiveChat } = useIntercom();
  const logout = useLogout();

  return (
    <FlexLayout direction='column' className='p-8 pr-16'>
      <Accordion type='single' className='rounded-8' collapsible>
        <AccordionItem
          value='support'
          className='h rounded-8 bg-color-background-surface-primary p-0 text-color-text-primary'
        >
          <AccordionTrigger className='p-8 hover:bg-color-background-surface-hover'>
            <FlexLayout spacing={24}>
              <Help />
              <Body weight='emphasis'>Support</Body>
            </FlexLayout>
          </AccordionTrigger>
          <AccordionContent className='mx-0 w-full pt-8'>
            <FlexLayout direction='column' spacing={8}>
              <AppSideBarItem
                item={{ title: "What's new", onClick: () => openWhatsNew(true), icon: <WhatsNew /> }}
                className='px-0'
              />
              <AppSideBarItem
                item={{
                  title: 'Live chat',
                  onClick: () => {
                    openLiveChat();
                    setNavDrawerExpanded(false);
                  },
                  icon: <Chat />,
                }}
                className='px-0'
              />
              <AppSideBarItem
                item={{
                  title: 'Swyftx Support centre',
                  onClick: () => {
                    window.open(Links.help.home, '_blank', 'noopener,noreferrer');
                    setNavDrawerExpanded(false);
                  },
                  icon: <Help />,
                }}
                className='px-0'
              />
              <AppSideBarItem
                item={{
                  title: 'Swyftx learn',
                  onClick: () => {
                    window.open(Links.learn.root, '_blank', 'noopener,noreferrer');
                    setNavDrawerExpanded(false);
                  },
                  icon: <Learn />,
                }}
                className='px-0'
              />
              <AppSideBarItem
                item={{
                  title: 'Provide feedback',
                  onClick: () => {
                    usersnapApi?.show(feedbackID).then((widgetApi) => widgetApi.open({}));
                    setNavDrawerExpanded(false);
                  },
                  icon: <Heart />,
                }}
                className='px-0'
              />
            </FlexLayout>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      <Button
        size='lg'
        color='destructive'
        variant='outlined'
        className='mt-16'
        leadingIcon={<LogOut />}
        onClick={logout}
      >
        Log out
      </Button>
    </FlexLayout>
  );
});

export { SideBarMobileFooter };

import { useEffect, useMemo, useState } from 'react';

import { Bank, CreditCard, PayID } from '@swyftx/aviary/icons/outlined';

import { getStripeLimitsAndFees, GetStripeMetadataResponse } from '@shared/api/@types/stripeService';
import { DepositMethodEnum, FiatCodeEnum } from '@shared/enums';
import {
  MaxDailyAUDDeposit,
  MaxDailyNZDDeposit,
  MaxWeeklyAUDDeposit,
  MaxWeeklyNZDDeposit,
  MinDailyAUDDeposit,
  MinDailyNZDDeposit,
} from '@shared/enums/lib/cardDepositLimitsEnum';
import { UserStore } from '@shared/store';
import { formatCurrency } from '@shared/utils';

import { DepositMethodItem } from '@Wallet/subroutes/SingleWallet/components/SingleWalletFiatDeposit/types';

import { AppFeature, useIsFeatureEnabled } from 'src/config';

export const useDepositMethods = (fiatAssetCode: string) => {
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const { isNZ } = UserStore.useUserStore;

  const [stripeMetadataAU, setStripeMetadataAU] = useState<GetStripeMetadataResponse>();
  const [stripeMetadataNZ, setStripeMetadataNZ] = useState<GetStripeMetadataResponse>();

  useEffect(() => {
    fetchStripeMetadata();
  }, [fiatAssetCode]);

  const fetchStripeMetadata = async () => {
    const stripeMetadataResponse = await getStripeLimitsAndFees(fiatAssetCode);
    if (fiatAssetCode === FiatCodeEnum.AUD) {
      setStripeMetadataAU(stripeMetadataResponse);
    } else {
      setStripeMetadataNZ(stripeMetadataResponse);
    }
  };

  const AUDDepositMethods: DepositMethodItem[] = useMemo(
    () => [
      {
        showIcon: false,
        icon: <Bank className='h-24 w-24 text-color-text-secondary' />,
        title: 'Bank transfer',
        method: DepositMethodEnum.MONOOVA_DIRECT_CREDIT,
        timeframe: 'Instant with Osko',
        depositLimit: 'Deposit up to $50,000 per transaction, per day',
        depositSummary: 'Instantly deposit to your account when using an Osko approved bank.',
      },
      {
        showIcon: false,
        title: 'Stripe (Card)',
        method: DepositMethodEnum.STRIPE,
        timeframe: 'Near Instant',
        depositLimit: `Between $${formatCurrency(stripeMetadataAU?.minimumAmount ?? MinDailyAUDDeposit.STRIPE)} 
          and $${formatCurrency(stripeMetadataAU?.maximumAmount ?? MaxDailyAUDDeposit.STRIPE)} per transaction`,
        depositSummary: `Visa and Mastercard only. 
          Maximum $${formatCurrency(stripeMetadataAU?.maximumWeeklyAmount ?? MaxWeeklyAUDDeposit.STRIPE)} per week.`,
        icon: <CreditCard className='h-24 w-24 text-color-text-secondary' />,
      },
      {
        showIcon: false,
        title: 'Banxa (Card)',
        method: DepositMethodEnum.BANXA,
        timeframe: '30-45 MINUTES',
        depositLimit: `Between $${formatCurrency(MinDailyAUDDeposit.BANXA)} 
          and $${formatCurrency(MaxDailyAUDDeposit.BANXA)} per transaction, per day`,
        depositSummary: 'Deposit into your account using your chosen card provider. Banxa and bank fees may apply.',
        icon: <CreditCard className='h-24 w-24 text-color-text-secondary' />,
      },
      {
        showIcon: true,
        title: 'PayID',
        method: DepositMethodEnum.MONOOVA_PAYID,
        timeframe: 'Near Instant',
        depositLimit: 'Deposit up to $50,000 per transaction, per day',
        depositSummary: 'Generate a unique PayID to use for depositing into your account.',
        icon: <PayID className='h-24 w-24 text-color-text-secondary' />,
      },
    ],
    [stripeMetadataAU],
  );

  const NZDDepositMethods: DepositMethodItem[] = useMemo(
    () => [
      {
        title: 'Banxa (Card)',
        method: DepositMethodEnum.BANXA,
        timeframe: 'Approx. 30-45 minutes',
        depositLimit: 'Deposit limits vary per transaction',
        depositSummary: 'Deposit into your account using your chosen card provider. Banxa and bank fees may apply.',
        icon: <CreditCard className='h-24 w-24 text-color-text-secondary' />,
      },
      {
        showIcon: false,
        title: 'Stripe (Card)',
        method: DepositMethodEnum.STRIPE,
        timeframe: 'Near Instant',
        depositLimit: `Between $${formatCurrency(stripeMetadataNZ?.minimumAmount ?? MinDailyNZDDeposit.STRIPE)} 
          and $${formatCurrency(stripeMetadataNZ?.maximumAmount ?? MaxDailyNZDDeposit.STRIPE)} per transaction`,
        depositSummary: `Visa and Mastercard only. 
          Maximum $${formatCurrency(stripeMetadataNZ?.maximumWeeklyAmount ?? MaxWeeklyNZDDeposit.STRIPE)} per week.`,
        icon: <CreditCard className='h-24 w-24 text-color-text-secondary' />,
      },
      {
        title: 'Bank transfer',
        disabled: true,
        method: DepositMethodEnum.NZD_BANK_TRANSFER,
        timeframe: '1 - 3 Days',
        depositLimit: 'Deposit up to $50,000 per transaction, per day',
        depositSummary: 'Deposit funds directly from your bank account',
        icon: <Bank className='h-24 w-24 text-color-text-secondary' />,
      },
    ],
    [stripeMetadataNZ],
  );

  return useMemo(() => {
    const isStripeEnabled = isFeatureEnabled(AppFeature.Stripe);
    const isStripeAUOnly = isFeatureEnabled(AppFeature.StripeAUOnly);
    // this check will cater for both AU and NZ users
    const stripeEnabled = isStripeEnabled && (!isStripeAUOnly || !isNZ());

    if (fiatAssetCode === FiatCodeEnum.AUD) {
      // if Stripe is not enabled, remove it from the list of deposit methods
      if (!stripeEnabled) {
        return AUDDepositMethods.filter((method) => method.method !== DepositMethodEnum.STRIPE);
      }
      return AUDDepositMethods;
    }

    if (fiatAssetCode === FiatCodeEnum.NZD) {
      // NZ users : if Stripe is enabled but only for AU, filter stripe out of deposit methods
      // AU users : Stripe should not available for NZD
      if (!stripeEnabled || !isNZ()) {
        return NZDDepositMethods.filter((method) => method.method !== DepositMethodEnum.STRIPE);
      }
      return NZDDepositMethods;
    }

    throw new Error(`Attempting to deposit to unknown country: ${fiatAssetCode}. Deposit funds. Select Deposit.`);
  }, [fiatAssetCode, isFeatureEnabled, isNZ, AUDDepositMethods, NZDDepositMethods]);
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Body, Numeric } from '@swyftx/aviary/atoms/Typography';
import { NumericProps } from '@swyftx/aviary/atoms/Typography/Numeric/Numeric.styles';
import { Notification, pxToRem, Stack, Typography } from '@swyftx/react-web-design-system';

import { AssetIcon } from '@global-components/AssetIcon/AssetIcon';
import { FormattedText } from '@global-components/Text';

import { Big } from '@shared/safe-big';
import { UniversalTradeStore } from '@shared/store';
import { TradeType } from '@shared/store/universalTradeStore/@types/universalTradeTypes';
import { formatCurrency } from '@shared/utils';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';
import { useUniversalTradeAsset } from '@hooks/Trade';
import { FeeBreakdown } from '@hooks/Trade/types/useUniversalTrade.types';

import { getOrderAction } from '@Trade/components/UniversalTradePanel/components/TradeFeeBreakdown/TradeFeeBreakdown.utils';
import { TradeActionEnum } from '@Trade/types';

import { observer } from 'mobx-react-lite';

type TradeFeeBreakdownItemProps = {
  tradeDataKey: string;
  feeBreakdown: FeeBreakdown;
};

const typographyProps: NumericProps = {
  color: 'secondary',
};

const TradeFeeBreakdownItem: React.FC<TradeFeeBreakdownItemProps> = observer(({ tradeDataKey, feeBreakdown }) => {
  const {
    limitAsset,
    nonLimitAsset,
    fromAsset,
    toAsset,
    triggerFromAsset,
    triggerToAsset,
    data,
    getSpendAmount,
    getReceiveAmount,
    fee,
  } = useUniversalTradeAsset(tradeDataKey);

  const { tradeType } = UniversalTradeStore;

  const { feeInLimit, feeInNonLimit } = feeBreakdown;

  const userCountry = useCountryAsset();
  const { t } = useTranslation('trade', { keyPrefix: 'tradeFeeBreakdown' });

  if (!fromAsset || !toAsset || !limitAsset || !nonLimitAsset || !userCountry) {
    return <Notification severity='error'>{t('labels.somethingWrong')}</Notification>;
  }

  const orderAction = getOrderAction(fromAsset, toAsset);
  const isFromEstimated = limitAsset.id !== fromAsset?.id;
  const isToEstimated = limitAsset.id !== toAsset?.id;

  const actionAssetCode = orderAction === TradeActionEnum.Buy ? toAsset.code : fromAsset.code;

  const formattedAssetQuantity = `${isFromEstimated ? '~' : ''}
      ${formatCurrency(getSpendAmount(), fromAsset, {
        appendCode: true,
      })}`;

  const formattedExpectedAssetReceived = `${isToEstimated ? '~' : ''}${formatCurrency(getReceiveAmount(), toAsset, {
    appendCode: true,
  })}`;

  let formattedRate = 'N/A';
  let formattedCode = '';
  const isTrigger = tradeType === TradeType.OnTrigger;
  const exchangeRate = isTrigger ? data.customTrigger : data.ratePrice;
  if (exchangeRate) {
    const inverse = orderAction === TradeActionEnum.Sell;
    const displayExchangeRate = !inverse || isTrigger ? exchangeRate : Big(1).div(exchangeRate);
    const displayExchangeAsset = !inverse || isTrigger ? triggerFromAsset : triggerToAsset;

    formattedRate = formatCurrency(displayExchangeRate, displayExchangeAsset, { hideCode: true, appendCode: false });
    formattedCode =
      !inverse || isTrigger
        ? `${triggerFromAsset?.code}/${triggerToAsset?.code}`
        : `${triggerToAsset?.code}/${triggerFromAsset?.code}`;
  }

  return (
    <Stack spacing={1}>
      <Stack width='100%' direction='row' justifyContent='space-between' alignItems='center'>
        <Typography fontWeight={600} fontSize={pxToRem(16)}>
          {t(orderAction as any)} {actionAssetCode}
        </Typography>
        <Stack spacing={1}>
          <Stack direction='row' spacing={1} alignItems='center'>
            <AssetIcon asset={fromAsset} size={20} />
            <Typography fontSize={14} fontWeight={400} color='text.primary'>
              {formattedAssetQuantity}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack width='100%' direction='row' justifyContent='space-between' alignItems='center'>
        <Body color='secondary'>{t('labels.receive')}</Body>
        <Stack direction='row' spacing={1} alignItems='center'>
          <AssetIcon asset={toAsset} size={18} />
          <Numeric {...typographyProps}>{formattedExpectedAssetReceived}</Numeric>
        </Stack>
      </Stack>
      <Stack width='100%' direction='row' justifyContent='space-between' alignItems='center'>
        <Body color='secondary'>{t('labels.rate')}</Body>
        <Numeric {...typographyProps}>
          {formattedRate} {formattedCode}
        </Numeric>
      </Stack>
      <Stack width='100%' direction='row' justifyContent='space-between' alignItems='center'>
        <Body color='secondary'>
          {t('labels.fee')} (
          {Big(fee || 0)
            .times(100)
            .toFixed(2)}
          %)
        </Body>

        <Stack direction='column'>
          <FormattedText
            typographyProps={{ ...typographyProps, weight: 'emphasis', color: 'primary' }}
            value={feeInLimit}
            prefix='~'
            currency={limitAsset}
            formatOpts={{ appendCode: true, hideCode: false }}
            secondaryText={{
              typographyProps,
              value: `≈ ~${formatCurrency(feeInNonLimit, nonLimitAsset, { appendCode: true })}`,
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
});

TradeFeeBreakdownItem.displayName = 'TradeFeeBreakdownItem';

export { TradeFeeBreakdownItem };

import React, { useEffect, useMemo } from 'react';

import { Notification } from '@swyftx/aviary/atoms/Notification';
import { Stack } from '@swyftx/react-web-design-system';

import { Asset } from '@shared/api';
import { DepositMethodEnum } from '@shared/enums';

import { CardDepositProvider } from '@hooks/Deposit/useCardDepositProviders';
import { useDeposit } from '@hooks/Deposit/useDeposit';
import { useTheme } from '@hooks/useTheme';

import { DepositReceiveProps } from './Deposit.types';
import { DepositBankTransfer } from './TransferTypes/BankTransfer';
import { DepositCreditCardTransfer } from './TransferTypes/CreditCard';
import { DepositPayIdTransfer } from './TransferTypes/PayId';
import { DepositFaqAccordion } from './components/DepositFaqAccordion';
import { DepositTabs } from './components/DepositTabs';

type Props = {
  asset: Asset;
  depositType?: DepositMethodEnum;
  setDepositType: React.Dispatch<React.SetStateAction<DepositMethodEnum>>;
} & DepositReceiveProps;

export const Deposit: React.FC<Props> = ({
  asset,
  setDisabled,
  setOnAction,
  onClose,
  setHideContinue,
  depositType = asset.code === 'NZD' ? DepositMethodEnum.NZD_BANK_TRANSFER : DepositMethodEnum.BANXA,
  setDepositType,
}) => {
  const { theme } = useTheme();

  const { depositDescription } = useDeposit({ asset, transferType: depositType, fetchAddressDetails: false });

  useEffect(() => {
    setDisabled(true);

    // hide continue step for these deposit methods
    if (setHideContinue) {
      if (
        depositType === DepositMethodEnum.NZD_BANK_TRANSFER ||
        depositType === DepositMethodEnum.MONOOVA_DIRECT_CREDIT ||
        depositType === DepositMethodEnum.MONOOVA_PAYID
      ) {
        setHideContinue(true);
      } else {
        setHideContinue(false);
      }
    }
  }, [setDisabled, setHideContinue, depositType]);

  const content = useMemo(() => {
    switch (depositType) {
      case DepositMethodEnum.MONOOVA_PAYID:
        return <DepositPayIdTransfer asset={asset} />;
      case DepositMethodEnum.BANXA:
      case DepositMethodEnum.STRIPE:
        return (
          <DepositCreditCardTransfer
            asset={asset}
            setDisabled={setDisabled}
            setOnAction={setOnAction}
            setDepositType={setDepositType}
            onClose={onClose}
            cardDepositProvider={
              depositType === DepositMethodEnum.STRIPE ? CardDepositProvider.Stripe : CardDepositProvider.Banxa
            }
          />
        );
      case DepositMethodEnum.MONOOVA_DIRECT_CREDIT:
      case DepositMethodEnum.NZD_BANK_TRANSFER:
      default:
        return <DepositBankTransfer asset={asset} />;
    }
  }, [asset, depositType, onClose, setDepositType, setDisabled, setOnAction]);

  return (
    <Stack
      spacing={2}
      sx={{
        '.MuiAccordion-root::before': {
          backgroundColor: 'initial !important',
        },
      }}
    >
      {(depositType === DepositMethodEnum.MONOOVA_PAYID ||
        depositType === DepositMethodEnum.MONOOVA_DIRECT_CREDIT ||
        depositType === DepositMethodEnum.NZD_BANK_TRANSFER) && (
        <Notification title='You may experience delays from your bank' severity='warning'>
          Deposits may be subject to a temporary, 24hr hold for the purpose of conducting additional compliance checks
          by your banking institution.
        </Notification>
      )}

      <Notification title='Deposits must be in the same name as your Swyftx account.' severity='info'>
        Deposits made from a different name may be delayed.
      </Notification>

      <Stack borderRadius='8px' overflow='auto' border={`1px solid ${theme.palette.divider}`}>
        <DepositTabs transferType={depositType} setTransferType={setDepositType} asset={asset} />

        {content}
      </Stack>

      <DepositFaqAccordion depositDescription={depositDescription} />
    </Stack>
  );
};

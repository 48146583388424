import { NewsTileTab, NewsTileView } from '@shared/api';

import { SwyftxAnalyticsData } from '../eventTypes';

export enum AnalyticsEventType {
  SignUp = 'Signup',
  Login = 'Login',
  Navigation = 'Navigation',
  RequestComplete = 'RequestComplete',
  Track = 'Track',
  Screen = 'Screen',
  Page = 'Page',
}

export interface AnalyticsEvent<T> {
  type: AnalyticsEventType;
  data: T;
}

export interface RequestCompleteEvent {
  path: string;
  method: string;
  data?: any;
  host?: string;
  query?: object;
  params?: object;
  response: any;
}

export type ViewportLayout = 'mobile' | 'desktop';

export interface AssetBreakdown {
  assetId: number;
  assetCode?: string;
  amount: number;
  portion?: number;
}

export enum NewsFeedTileTrackEventType {
  ViewedLatestNews = 'Viewed Latest News',
  RefreshedLatestNews = 'Refreshed Latest News',
  ClickedNewsArticle = 'Clicked News Article',
  SwitchedLatestNewsView = 'Switched Latest News View',
  ClickedShowMoreLatestNews = 'Clicked Show More Latest News',
  SearchedLatestNews = 'Searched Latest News',
  NoNewsFilterAvailable = 'No News Filter Available',
  NoNewsReturned = 'No News Returned',
  SwitchedNewsTab = 'Switched News Tab',
  ViewedLatestNewsAssetPage = 'Viewed Latest News on Asset Page',
}

export interface LatestNewsEventData extends SwyftxAnalyticsData {
  eventType: NewsFeedTileTrackEventType;
  props?: LatestNewsEventDataProps;
}

export interface LatestNewsEventDataProps {
  news_feed_preference?: NewsTileTab;
  news_tile_view?: NewsTileView;

  article_title?: string;
  article_link?: string;
  article_assets?: string[];
  article_published_date?: string;

  filter_type?: string;
  filter_searched?: string;
  filter_selection?: string[];

  switched_to?: NewsTileTab;
  switched_view?: NewsTileView;
}

export enum ReferAFriendEventType {
  ViewedReferAFriendDashboard = 'Viewed Refer A Friend Dashboard Tile',
  CopiedReferAFriend = 'Copied Refer A Friend Link',
  ViewedReferAFriendPage = 'Viewed Refer A Friend Page',
  ClickedReferDashboardBanner = 'Clicked Refer A Friend Dashboard Banner',
  ClickedAffiliatesOnRefer = 'Redirect To Affiliate Page',
}

export interface ReferAFriendEventProps {
  variant?: string;
  title?: string;
  actionButton?: string;
}

export interface ReferAFriendEventData extends SwyftxAnalyticsData {
  eventType: ReferAFriendEventType;
  props: ReferAFriendEventProps;
}

// TODO: remove once migrated to analytics repository
export type TrackEventData = LatestNewsEventData | ReferAFriendEventData;
export type TrackEventType = NewsFeedTileTrackEventType | ReferAFriendEventType;
export type TrackEventDataProps = LatestNewsEventDataProps | ReferAFriendEventProps;

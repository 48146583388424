import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Fade } from '@mui/material';

import { Checkbox } from '@swyftx/aviary/atoms/Checkbox';
import { Input } from '@swyftx/aviary/atoms/Input';
import { Label } from '@swyftx/aviary/atoms/Label';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { VerificationRequiredForStripeBanner } from '@swyftx/aviary/complex/TransferModal/components/TransferRootStep/TransferDepositReceive/Deposit/VerificationRequiredForStripeBanner';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { Asset } from '@shared/api';
import { getStripeLimitsAndFees, GetStripeMetadataResponse } from '@shared/api/@types/stripeService';
import { DepositMethodEnum } from '@shared/enums';
import {
  MaxDailyAUDDeposit,
  MaxDailyNZDDeposit,
  MinDailyAUDDeposit,
  MinDailyNZDDeposit,
} from '@shared/enums/lib/cardDepositLimitsEnum';
import { Big } from '@shared/safe-big';
import { UIStore, UserStore } from '@shared/store';
import { formatCurrency } from '@shared/utils';

import { useAvo } from '@hooks/Avo/useAvo';
import { CardDepositProvider } from '@hooks/Deposit/useCardDepositProviders';
import { useDepositDescription } from '@hooks/Deposit/useDepositDescription';
import { generateBankAddress } from '@services/DepositService';
import { isValidDepositInput } from '@services/DepositService/DepositService.utils';

import { DepositReceiveProps } from '../Deposit.types';

type Props = {
  asset: Asset;
  cardDepositProvider: CardDepositProvider;
} & DepositReceiveProps;

type DepositLimits = {
  [key: string]: {
    min: number;
    max: number;
  };
};
const depositLimits: DepositLimits = {
  NZD: {
    min: MinDailyNZDDeposit.BANXA,
    max: MaxDailyNZDDeposit.BANXA,
  },
  AUD: {
    min: MinDailyAUDDeposit.BANXA,
    max: MaxDailyAUDDeposit.BANXA,
  },
};
const defaultLimits = {
  min: MinDailyAUDDeposit.BANXA,
  max: MaxDailyAUDDeposit.BANXA,
};

const amountAfterFee = (amount: Big, fee: Big): string =>
  amount.minus(amount.times(fee.div(100) ?? 1).round(18, 0)).toFixed(2);

const DepositCreditCardTransfer: React.FC<Props> = ({
  asset,
  setDisabled,
  setOnAction,
  onClose,
  cardDepositProvider,
}) => {
  const { t } = useTranslation('wallet', { keyPrefix: 'depositInformation' });
  const [stripeMetadata, setStripeMetadata] = useState<GetStripeMetadataResponse>();
  const { addToastMessage } = UIStore.useUIStore;

  const { depositCreditCardText } = useDepositDescription(asset.code, cardDepositProvider);

  const { min, max } = depositLimits[asset.code] || defaultLimits;
  // Banxa min and max values can be set to defaults, Stripe values are set in stripeMetadata
  const minBanxaValue = depositCreditCardText.extraBankAddressDetails?.minimumOrder || min;
  const maxBanxaValue = depositCreditCardText.extraBankAddressDetails?.maximumOrder || max;

  const { pathname } = useLocation();
  const { isKyc1Complete } = UserStore.useUserStore;

  const [value, setValue] = useState<string>('');
  const [valueAsBig, setValueAsBig] = useState<Big>();
  const [error, setError] = useState<string>('');
  const [acceptSharing, setAcceptSharing] = useState(false);

  const { openModal } = useModal();
  const avo = useAvo();

  useEffect(() => {
    (async () => {
      if (cardDepositProvider === CardDepositProvider.Stripe) {
        const stripeMetadataResponse = await getStripeLimitsAndFees(asset.code);
        setStripeMetadata(stripeMetadataResponse);
      }
    })();

    // reset state when swapping to the other card deposit method
    setValue('');
    setValueAsBig(Big(0));
    setError('');
    setAcceptSharing(false);
  }, [cardDepositProvider, asset]);

  useEffect(() => {
    let shouldDisableButton = error.length > 0 || value.length <= 0;
    if (cardDepositProvider === CardDepositProvider.Banxa) {
      shouldDisableButton = shouldDisableButton || !acceptSharing;
    }
    setDisabled(shouldDisableButton);
  }, [acceptSharing, cardDepositProvider, error, setDisabled, value.length]);

  useEffect(() => {
    avo.clickedCardDeposit({
      screen: pathname,
      depositOption: cardDepositProvider,
    });
  }, [avo, cardDepositProvider, pathname]);

  const handleProceedBanxa = async (val: string) => {
    try {
      const { redirectUrl } = await generateBankAddress(asset.code, DepositMethodEnum.BANXA, Number(val));
      window.open(redirectUrl, '_blank', 'noopener,noreferrer');
      avo.proceededWithCardDeposit({
        screen: pathname,
        depositOption: cardDepositProvider,
        amount: +val,
        currency: asset.code,
      });
      onClose();
    } catch (e) {
      avo.cardDepositFailed({
        screen: pathname,
        depositOption: cardDepositProvider,
        amount: +val,
        currency: asset.code,
      });
      addToastMessage({
        severity: 'error',
        message: t('banxa.errorGeneratingDetails'),
      });
    }
  };

  const handleProceedStripe = async (val: string) => {
    avo.proceededWithCardDeposit({
      screen: pathname,
      depositOption: CardDepositProvider.Stripe,
      amount: +val,
      currency: asset.code,
    });
    openModal(Modals.StripeCardDeposit, { amount: val });
  };

  const handleProceed = async (val: string) => {
    if (cardDepositProvider === CardDepositProvider.Banxa) {
      handleProceedBanxa(val);
    } else {
      handleProceedStripe(val);
    }
  };

  const updateValue = (val: string) => {
    if (!isValidDepositInput(val)) {
      return;
    }

    setValue(val);

    if (!val) {
      setError('');
      setOnAction(() => {});
      setValueAsBig(Big(0));
      return;
    }

    const valueParsed = Big(val);
    setValueAsBig(valueParsed);

    const minInputVal =
      cardDepositProvider === CardDepositProvider.Stripe && stripeMetadata
        ? stripeMetadata.minimumAmount
        : minBanxaValue;
    const maxInputVal =
      cardDepositProvider === CardDepositProvider.Stripe && stripeMetadata
        ? stripeMetadata.maximumAmount
        : maxBanxaValue;

    if (valueParsed.toNumber() < minInputVal) {
      setError(t('banxa.minError', { minValue: minInputVal, code: asset.code }));
      setOnAction(() => {});
    } else if (valueParsed.toNumber() > maxInputVal) {
      setError(t('banxa.maxError', { maxValue: maxInputVal, code: asset.code }));
      setOnAction(() => {});
    } else {
      setError('');
      setOnAction(() => () => handleProceed(val));
    }
  };

  const showCheckbox = cardDepositProvider === CardDepositProvider.Banxa;
  const showVerificationRequiredBanner = cardDepositProvider === CardDepositProvider.Stripe && !isKyc1Complete();

  return (
    <Fade in timeout={500}>
      <FlexLayout direction='column' spacing={16} className='p-16'>
        <Body>
          {cardDepositProvider === CardDepositProvider.Stripe
            ? t('stripe.cardForm.providerTitle', {
                feePercentage: stripeMetadata?.feePercentage,
                min: `${formatCurrency(stripeMetadata?.minimumAmount)}`,
                max: `${formatCurrency(stripeMetadata?.maximumAmount)}`,
                weeklyMax: `${formatCurrency(stripeMetadata?.maximumWeeklyAmount)}`,
              })
            : t('creditCard.steps.providerTitle', {
                value: `$${formatCurrency(depositCreditCardText.extraBankAddressDetails?.maximumOrder)}`,
              })}
        </Body>

        {/* Stripe requires users to have completed KYC1, if they haven't, they will be shown a verification banner instead of input */}
        {showVerificationRequiredBanner ? (
          <VerificationRequiredForStripeBanner onClose={onClose} />
        ) : (
          <FlexLayout direction='column' spacing={8}>
            <Body weight='emphasis'>
              {t('banxa.amountToDeposit', { code: asset.code })}
              <span className='inline text-color-text-error'>*</span>
            </Body>
            <Input
              error={!!error}
              value={value}
              onChange={(e) => updateValue(e.target.value)}
              leading='$'
              trailing={asset.code}
              placeholder={t('creditCard.steps.enterAnAmount')}
            />

            {error && (
              <Body color='error' className='font-bold text-color-text-error'>
                {error}
              </Body>
            )}

            {cardDepositProvider === CardDepositProvider.Stripe && (
              <Body className='text-[0.8rem]'>
                {t('stripe.depositInfo.amountToReceive') + ' '}
                <span className='font-bold text-color-text-accent'>
                  ~${valueAsBig ? amountAfterFee(valueAsBig, Big(stripeMetadata?.feePercentage)) : '0.00'}
                </span>
              </Body>
            )}
          </FlexLayout>
        )}

        {showCheckbox && (
          <FlexLayout spacing={8} alignItems='center'>
            <Checkbox checked={acceptSharing} onCheckedChange={(checked) => setAcceptSharing(!!checked)} id='terms' />
            <Label htmlFor='terms'>{depositCreditCardText.label}</Label>
          </FlexLayout>
        )}
      </FlexLayout>
    </Fade>
  );
};

export { DepositCreditCardTransfer };

import { api } from '@shared/api';
import { FiatCodeEnum } from '@shared/enums';
import {
  CardDepositFee,
  MaxDailyAUDDeposit,
  MaxDailyNZDDeposit,
  MaxWeeklyAUDDeposit,
  MaxWeeklyNZDDeposit,
  MinDailyAUDDeposit,
  MinDailyNZDDeposit,
} from '@shared/enums/lib/cardDepositLimitsEnum';
import { assetService } from '@shared/services';

export enum Source {
  Chat = 'chat', // Order was placed using a chat service
  DesktopWeb = 'desktop_web', // Order was placed on the website, using a desktop device
  InStore = 'in_store', // Order was placed on an in store online device
  MobileApp = 'mobile_app', // Order was placed on the mobile app, using a mobile device
  MobileAppAndroid = 'mobile_app_android', // Order was placed on the mobile app, using an android mobile device
  MobileAppIos = 'mobile_app_ios', // Order was placed on the mobile app, using an iOS mobile device
  MobileWeb = 'mobile_web', // Order was placed on the mobile website, using a mobile device
  Phone = 'phone', // Order was placed over the phone by a call center support or sales agent
  ShopifyDraftOrder = 'shopify_draft_order', // Order was a Shopify draft order
  Subscription = 'subscription', // Order is a recurring subscription
  ThirdParty = 'third_party', // Order was placed on a third party domain
  Unknown = 'unknown', // Order's origin is unknown
  Web = 'web', // Order was placed on the website, with no available info about the type of device used
}

export interface RiskifiedMetadata {
  /**
   * Not provided by the client, gathered from the request
   */
  browser_ip: string;
  source: Source;
  accept_language: string;
  user_agent: string;
  cart_token: string;
}

export interface InitiateStripeWithRiskifiedRequest {
  data: {
    amount: string;
    assetId: number;
    riskified: Omit<RiskifiedMetadata, 'browser_ip'>;
  };
}

export interface InitiateStripeWithoutRiskifiedRequest {
  data: {
    amount: string;
    assetId: number;
  };
}

export type InitiateStripeRequest = InitiateStripeWithoutRiskifiedRequest | InitiateStripeWithRiskifiedRequest;

export type InitiateStripeResponse = {
  data: {
    publishableKey: string;
    clientSecret: string;
    fee?: {
      amount: string;
    };
  };
};

export interface GetStripeMetadataRequest {
  assetId: number;
}

export type GetStripeMetadataResponse = {
  feePercentage: string;
  maximumAmount: string;
  minimumAmount: string;
  maximumWeeklyAmount: string;
};

export const getStripeLimitsAndFees = async (assetCode: string): Promise<GetStripeMetadataResponse> => {
  const isNZD = assetCode === FiatCodeEnum.NZD;

  const defaultValues = {
    minDepositAmount: isNZD ? MinDailyNZDDeposit.STRIPE : MinDailyAUDDeposit.STRIPE,
    maxDepositAmount: isNZD ? MaxDailyNZDDeposit.STRIPE : MaxDailyAUDDeposit.STRIPE,
    maxWeeklyAmount: isNZD ? MaxWeeklyNZDDeposit.STRIPE : MaxWeeklyAUDDeposit.STRIPE,
    feePercentage: CardDepositFee.STRIPE,
  };

  try {
    // get the stripe deposit limits and fees via the API if possible
    // note: the assetId param is required for the API call, so we'll pass in -1 and fall through to default values if the asset is not available
    const results = await api.endpoints.getStripeMetadata({
      params: { assetId: assetService.getAssetByCode(assetCode)?.id ?? -1 },
    });

    return {
      minimumAmount: results?.data?.minimumAmount ?? defaultValues.minDepositAmount.toString(),
      maximumAmount: results?.data?.maximumAmount ?? defaultValues.maxDepositAmount.toString(),
      maximumWeeklyAmount: results?.data?.maximumWeeklyAmount ?? defaultValues.maxWeeklyAmount.toString(),
      feePercentage: results?.data?.feePercentage ?? defaultValues.feePercentage.toString(),
    };
  } catch (error) {
    // if stripe metadata endpoint values are not populated, fall back to default values
    return {
      minimumAmount: defaultValues.minDepositAmount.toString(),
      maximumAmount: defaultValues.maxDepositAmount.toString(),
      maximumWeeklyAmount: defaultValues.maxWeeklyAmount.toString(),
      feePercentage: defaultValues.feePercentage.toString(),
    };
  }
};

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Button, Modal, Stack } from '@swyftx/react-web-design-system';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { Asset } from '@shared/api/@types/markets';
import { DepositMethodEnum } from '@shared/enums';
import {
  MaxDailyAUDDeposit,
  MaxDailyNZDDeposit,
  MinDailyAUDDeposit,
  MinDailyNZDDeposit,
} from '@shared/enums/lib/cardDepositLimitsEnum';
import { UIStore, UserStore } from '@shared/store';

import { useAvo } from '@hooks/Avo/useAvo';
import { CardDepositProvider, useCardDepositProviders } from '@hooks/Deposit/useCardDepositProviders';
import { useDepositDescription } from '@hooks/Deposit/useDepositDescription';
import { generateBankAddress } from '@services/DepositService';

import { observer } from 'mobx-react-lite';

import { CreditCardDepositModalBanxaDetails } from './CreditCardDepositModalBanxaDetails';
import { CreditCardDepositModalOverview } from './CreditCardDepositModalOverview';
import { CreditCardDepositModalRedirecting } from './CreditCardDepositModalRedirecting';

interface Props {
  asset: Asset;
  onClose: () => void;
}

enum CreditCardDepositModalStep {
  Overview = 0,
  BanxaDetails = 1,
  Redirecting = 2,
}

const CreditCardDepositModal: React.FC<Props> = observer(({ asset, onClose }) => {
  const { isUserVerified, userCountryCurrencyCode } = UserStore.useUserStore;
  const { addToastMessage } = UIStore.useUIStore;
  const { t } = useTranslation('wallet', { keyPrefix: 'depositInformation' });

  const [acceptKycSharing, setAcceptKycSharing] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [step, setStep] = useState<CreditCardDepositModalStep>(CreditCardDepositModalStep.Overview);
  const [value, setValue] = useState<string>('');
  const isNZD = asset.code === 'NZD';

  const { depositCreditCardText } = useDepositDescription(asset.code);
  const minValue =
    depositCreditCardText.extraBankAddressDetails?.minimumOrder || isNZD
      ? MinDailyNZDDeposit.BANXA
      : MinDailyAUDDeposit.BANXA;
  const maxValue =
    depositCreditCardText.extraBankAddressDetails?.maximumOrder || isNZD
      ? MaxDailyNZDDeposit.BANXA
      : MaxDailyAUDDeposit.BANXA;

  const supportedCardDepositProviders = useCardDepositProviders();

  // this implementation does not cater for both Banxa and Stripe being displayed at the same time
  // the preferred provider is Stripe here will be, if it is not enabled, Banxa is used
  const cardDepositProvider = supportedCardDepositProviders.includes(CardDepositProvider.Stripe)
    ? CardDepositProvider.Stripe
    : CardDepositProvider.Banxa;

  const { openModal } = useModal();
  const { pathname } = useLocation();
  const avo = useAvo();

  const handleProceed = async () => {
    try {
      const { redirectUrl } = await generateBankAddress(asset.code, DepositMethodEnum.BANXA, Number(value));
      window.open(redirectUrl, '_blank', 'noopener,noreferrer');
      avo.proceededWithCardDeposit({
        screen: pathname,
        depositOption: cardDepositProvider,
        amount: +value,
        currency: userCountryCurrencyCode,
      });
      onClose();
    } catch (e) {
      avo.cardDepositFailed({
        screen: pathname,
        depositOption: cardDepositProvider,
        amount: +value,
        currency: userCountryCurrencyCode,
      });
      addToastMessage({
        severity: 'error',
        message: t('banxa.errorGeneratingDetails'),
      });
    }
  };

  const handleContinue = () => {
    avo.clickedCardDeposit({
      screen: pathname,
      depositOption: cardDepositProvider,
    });
    setStep(CreditCardDepositModalStep.BanxaDetails);
  };

  const redirectingStep = () => {
    avo.proceededWithCardDeposit({
      screen: pathname,
      depositOption: cardDepositProvider,
      amount: +value,
      currency: userCountryCurrencyCode,
    });
    if (cardDepositProvider === CardDepositProvider.Banxa) {
      setStep(CreditCardDepositModalStep.Redirecting);
    } else {
      openModal(Modals.StripeCardDeposit, { amount: value });
      onClose();
    }
  };

  const shouldDisableButton = () => {
    let shouldDisable = !value || error.length > 0;
    if (cardDepositProvider === CardDepositProvider.Banxa) {
      shouldDisable = shouldDisable || !acceptKycSharing;
    }

    return shouldDisable;
  };

  const updateValue = (val: string) => {
    const valueParsed = Number(val);

    if (valueParsed < minValue) {
      setError(t('banxa.minError', { minValue, code: asset.code }));
    } else if (valueParsed > maxValue) {
      setError(t('banxa.maxError', { maxValue, code: asset.code }));
    } else {
      setError('');
    }

    setValue(val);
  };

  const getTitle = () => {
    switch (step) {
      case CreditCardDepositModalStep.BanxaDetails:
        return t('creditCard.steps.details', { cardDepositProvider });
      case CreditCardDepositModalStep.Redirecting:
        return t('creditCard.steps.redirecting');
      case CreditCardDepositModalStep.Overview:
      default:
        return t('creditCard.steps.overview');
    }
  };

  const getFooterContent = () => {
    switch (step) {
      case CreditCardDepositModalStep.BanxaDetails:
        return (
          <Stack direction='row' width='100%' height='100%' alignItems='center' justifyContent='flex-end' spacing={2}>
            <Button color='inherit' sx={{ color: 'text.secondary' }} onClick={onClose}>
              {t('creditCard.steps.cancel')}
            </Button>
            <Button
              variant='contained'
              disableElevation
              onClick={() => redirectingStep()}
              disabled={shouldDisableButton()}
            >
              {t('creditCard.steps.start')}
            </Button>
          </Stack>
        );
      case CreditCardDepositModalStep.Redirecting:
        return (
          <Stack direction='row' width='100%' height='100%' alignItems='center' justifyContent='flex-end' spacing={2}>
            <Button color='inherit' sx={{ color: 'text.secondary' }} onClick={onClose}>
              {t('creditCard.steps.cancel')}
            </Button>
            <Button variant='contained' onClick={handleProceed} disableElevation>
              {t('creditCard.steps.proceed')}
            </Button>
          </Stack>
        );
      case CreditCardDepositModalStep.Overview:
      default:
        return (
          <Stack direction='row' width='100%' height='100%' alignItems='center' justifyContent='flex-end' spacing={2}>
            <Button color='inherit' sx={{ color: 'text.secondary' }} onClick={onClose}>
              {t('creditCard.steps.cancel')}
            </Button>
            <Button variant='contained' onClick={handleContinue} disableElevation>
              {t('creditCard.steps.continue')}
            </Button>
          </Stack>
        );
    }
  };

  const getContent = () => {
    switch (step) {
      case CreditCardDepositModalStep.Overview:
        return <CreditCardDepositModalOverview asset={asset} />;
      case CreditCardDepositModalStep.BanxaDetails:
        return (
          <CreditCardDepositModalBanxaDetails
            assetCode={asset.code}
            value={value}
            setValue={updateValue}
            acceptKycSharing={acceptKycSharing}
            setAcceptKycSharing={setAcceptKycSharing}
            error={error}
          />
        );
      case CreditCardDepositModalStep.Redirecting:
        return <CreditCardDepositModalRedirecting asset={asset} amount={value} />;
      default:
        return <CreditCardDepositModalOverview asset={asset} />;
    }
  };

  return (
    <Modal
      id='credit-card-deposit-modal'
      open
      sx={{ width: '600px', '.MuiCardContent-root': { height: '100%' } }}
      HeaderProps={{ title: getTitle(), dismissible: true, divider: true }}
      FooterProps={!isUserVerified() ? undefined : { content: getFooterContent() }}
      onClose={onClose}
    >
      {getContent()}
    </Modal>
  );
});

CreditCardDepositModal.displayName = 'CreditCardDepositModal';

export { CreditCardDepositModal };

import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { Button, Modal, Stack } from '@swyftx/react-web-design-system';

import { api } from '@shared/api';
import { Source } from '@shared/api/@types/stripeService';
import { CardDepositFee } from '@shared/enums/lib/cardDepositLimitsEnum';
import { Big } from '@shared/safe-big';
import { UIStore, UserStore } from '@shared/store';

import { useScreenBreakpoints } from '@hooks/Layout/useScreenBreakpoints';
import { useRiskified } from '@hooks/useRiskified/useRiskified';

import { loadStripe, Stripe } from '@stripe/stripe-js';
import { AppFeature, useIsFeatureEnabled } from 'src/config';

import { StripeCardDepositWrapper } from './StripeCardDepositWrapper';
import { StripeLogo } from '../../../assets/PoweredByStripeLogo';

interface Props {
  amount: Big;
  onClose: () => void;
  onFailure?: () => void;
}

export const StripeCardDepositModal = ({ amount, onClose, onFailure }: Props) => {
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>();
  const [clientSecret, setClientSecret] = useState('');
  const [publicKey, setPublicKey] = useState('');
  const [feePercentage, setFeePercentage] = useState(Big(CardDepositFee.STRIPE));
  const [feeAmount, setFeeAmount] = useState<Big>(Big(0));
  const [onSubmit, setOnSubmit] = useState<() => void | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const { isLargeScreen } = useScreenBreakpoints();
  const { t } = useTranslation('wallet', { keyPrefix: 'depositInformation.stripe.cardForm' });
  const { addToastMessage } = UIStore.useUIStore;
  const { userCountryCurrency } = UserStore.useUserStore;
  const { getSid } = useRiskified();
  const isXs = useTailwindBreakpoint('xs');
  const isSm = useTailwindBreakpoint('sm');

  const isSmallScreen = useMemo(() => isXs || isSm, [isSm, isXs]);
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const handleSubmit = async () => {
    setIsLoading(true);

    if (onSubmit) {
      await onSubmit();
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (publicKey) {
      setStripePromise(loadStripe(publicKey));
    }
  }, [publicKey]);

  useEffect(() => {
    (async () => {
      try {
        // if riskified is enabled, we need to pass additional data in the payment request
        const requestData = isFeatureEnabled(AppFeature.Riskified)
          ? {
              data: {
                amount: amount.toString(),
                assetId: userCountryCurrency,
                riskified: {
                  source: isSmallScreen ? Source.MobileWeb : Source.DesktopWeb,
                  accept_language: navigator.language,
                  user_agent: navigator.userAgent,
                  cart_token: getSid(),
                },
              },
            }
          : {
              data: {
                amount: amount.toString(),
                assetId: userCountryCurrency,
              },
            };

        const { data } = await api.endpoints.generateStripePayment({
          data: requestData,
        });
        setClientSecret(data.data.clientSecret);
        setPublicKey(data.data.publishableKey);
        setFeeAmount(Big(data.data.fee?.amount ?? 0));
      } catch {
        onClose();
        addToastMessage({
          severity: 'error',
          message: t('loadingError'),
        });
      }
    })();
  }, [amount, userCountryCurrency]);

  useEffect(() => {
    (async () => {
      if (userCountryCurrency) {
        try {
          const { data } = await api.endpoints.getStripeMetadata({
            params: { assetId: userCountryCurrency },
          });
          setFeePercentage(Big(data.feePercentage));
        } catch {
          onClose();
          addToastMessage({
            severity: 'error',
            message: t('loadingError'),
          });
        }
      }
    })();
  }, [userCountryCurrency]);

  return (
    <Modal
      open
      onClose={onClose}
      HeaderProps={{ divider: true, dismissible: true, title: 'Deposit with Card' }}
      FooterProps={{
        divider: true,
        content: (
          <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
            <StripeLogo id='stripe-logo' width='120px' height='auto' alt='Stripe logo' />
            <Stack direction='row' spacing={1}>
              <Button color='inherit' sx={{ color: 'text.secondary' }} onClick={onClose}>
                {t('cancel')}
              </Button>
              <Button
                variant='contained'
                sx={{ padding: '10px' }}
                onClick={handleSubmit}
                loading={isLoading}
                disabled={isSubmitDisabled}
              >
                {t('confirm')}
              </Button>
            </Stack>
          </Stack>
        ),
      }}
      sx={{ width: isLargeScreen ? '590px' : '100%' }}
    >
      <Box minHeight='386px'>
        <StripeCardDepositWrapper
          clientSecret={clientSecret}
          stripePromise={stripePromise}
          onSuccess={onClose}
          onFailure={onFailure}
          setOnSubmit={setOnSubmit}
          setIsSubmitDisabled={setIsSubmitDisabled}
          amount={amount}
          feeAmount={feeAmount}
          feePercentage={feePercentage}
        />
      </Box>
    </Modal>
  );
};

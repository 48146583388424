import React from 'react';
import { useTranslation } from 'react-i18next';

import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

import { ArrowChevronDown } from '@swyftx/aviary/icons/outlined';
import { Divider, Stack, Typography } from '@swyftx/react-web-design-system';

import { DepositOverview } from '@services/DepositService/DepositService.types';

import { useIntercom } from 'react-use-intercom';

type Props = {
  depositDescription: DepositOverview;
};

const DepositFaqAccordion: React.FC<Props> = ({ depositDescription }) => {
  const { t } = useTranslation('wallet', { keyPrefix: 'depositInformation.FAQTitles' });
  const { show: showIntercom } = useIntercom();

  const getAccordionDetail = (
    title: string,
    body?: string,
    link?: string,
    linkDescription?: string,
    customOnClick?: () => void,
  ) => {
    if (!body) return null;

    return (
      <AccordionDetails>
        <Stack direction='column' spacing={1}>
          <Typography fontSize={14} fontWeight={500}>
            {title}
          </Typography>
          <Typography fontSize={14} fontWeight={400} color='text.secondary' whiteSpace='pre-line'>
            {body}
            {linkDescription && (
              <Typography
                sx={{ cursor: 'pointer' }}
                fontSize={14}
                display='inline'
                color='primary'
                onClick={customOnClick || (() => window.open(link ?? '')?.focus())}
              >
                {' '}
                {linkDescription ?? ''}
              </Typography>
            )}
          </Typography>
        </Stack>
      </AccordionDetails>
    );
  };

  return (
    <Accordion
      elevation={0}
      disableGutters
      sx={{
        backgroundColor: 'inherit',
        '.MuiAccordionSummary-root': {
          paddingX: 0,
        },
        '.MuiAccordionDetails-root': {
          paddingX: 0,
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ArrowChevronDown className='h-20 w-20' />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography fontWeight={600} fontSize={14}>
          {t('main')}
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        <Divider />
      </AccordionDetails>

      {getAccordionDetail(
        t('processingTime'),
        depositDescription.processingTime.description,
        depositDescription.processingTime.link,
        depositDescription.processingTime.linkDescription,
      )}

      {getAccordionDetail(t('successfulDeposits'), depositDescription.successfulDeposits)}

      {getAccordionDetail(t('limits'), depositDescription.limits)}

      {getAccordionDetail(t('fees'), depositDescription.fees)}

      {getAccordionDetail(t('sameNameDeposit'), depositDescription.sameNameDeposit)}

      {getAccordionDetail(t('verification'), depositDescription.verification)}

      {getAccordionDetail(t('supportedCardsStripe'), depositDescription.supportedCards)}

      {getAccordionDetail(t('branchDeposits'), depositDescription.branchDeposits, '', t('chat'), showIntercom)}
    </Accordion>
  );
};

export { DepositFaqAccordion };

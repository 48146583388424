import React, { useCallback, useEffect, useRef, useState } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { DateTime } from 'luxon';

import { DateOfBirthInputField } from './DateOfBirthInputField';

type Props = {
  value?: DateTime;
  error?: string;
  onChange: (value: DateTime) => void;
};

const parseValueYear = (val?: DateTime) => {
  if (!val) return '';

  if (val.year < 0) return '';

  return val.year.toString();
};

const DateOfBirthInput: React.FC<Props> = ({ value, error, onChange }) => {
  const [day, setDay] = useState<string | undefined>(value?.day.toString() || undefined);
  const dayRef = useRef<HTMLInputElement>(null);
  const [month, setMonth] = useState<string | undefined>(value?.month.toString() || undefined);
  const monthRef = useRef<HTMLInputElement>(null);
  const [year, setYear] = useState<string | undefined>(parseValueYear(value));
  const yearRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const dayNum = parseInt(day ?? '');
    const monthNum = parseInt(month ?? '');
    const yearNum = parseInt(year ?? '');

    if (isNaN(dayNum) || isNaN(monthNum) || isNaN(yearNum)) return;

    const newDate = DateTime.fromObject({ day: dayNum, month: monthNum, year: yearNum });

    onChange(newDate);
  }, [day, month, onChange, year]);

  const handleOnKeyDown = useCallback(
    (
      e: React.KeyboardEvent<HTMLInputElement>,
      currentRef: React.RefObject<HTMLInputElement>,
      targetRef: React.RefObject<HTMLInputElement>,
    ) => {
      if (e.key === 'Backspace' && currentRef.current?.value.length === 0) {
        targetRef.current?.focus();
        e.stopPropagation();
        e.preventDefault();
      }
    },
    [],
  );

  const handleOnChange = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      updateMethod: React.Dispatch<React.SetStateAction<string | undefined>>,
      targetRef?: React.RefObject<HTMLInputElement>,
    ) => {
      const val = e.target.value;
      if (val.length > 2 && targetRef) {
        targetRef.current?.focus();
        return;
      } else {
        updateMethod(val);
        if (targetRef && val.length === 2) targetRef.current?.focus();
      }
    },
    [],
  );

  return (
    <FlexLayout direction='column' spacing={4}>
      <FlexLayout spacing={12}>
        <FlexLayout spacing={12} className='w-full'>
          <DateOfBirthInputField
            ref={dayRef}
            placeholder='DD'
            maxLength={2}
            value={day}
            onChange={(e) => handleOnChange(e, setDay, monthRef)}
            error={!!error?.length}
          />
          <DateOfBirthInputField
            ref={monthRef}
            placeholder='MM'
            maxLength={2}
            value={month}
            onKeyDown={(e) => handleOnKeyDown(e, monthRef, dayRef)}
            onChange={(e) => handleOnChange(e, setMonth, yearRef)}
            error={!!error?.length}
          />
        </FlexLayout>
        <FlexLayout spacing={12} className='w-full'>
          <DateOfBirthInputField
            ref={yearRef}
            placeholder='YYYY'
            maxLength={4}
            value={year}
            onKeyDown={(e) => handleOnKeyDown(e, yearRef, monthRef)}
            onChange={(e) => handleOnChange(e, setYear)}
            error={!!error?.length}
          />
        </FlexLayout>
      </FlexLayout>
      {error && (
        <Body color='error' size='small' weight='emphasis'>
          {error}
        </Body>
      )}
    </FlexLayout>
  );
};

export { DateOfBirthInput };

import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getStripeLimitsAndFees, GetStripeMetadataResponse } from '@shared/api/@types/stripeService';
import { Links } from '@shared/constants';
import {
  MaxDailyAUDDeposit,
  MaxDailyNZDDeposit,
  MaxMonthlyAUDDeposit,
  MaxMonthlyNZDDeposit,
  MinDailyAUDDeposit,
  MinDailyNZDDeposit,
} from '@shared/enums/lib/cardDepositLimitsEnum';
import { formatCurrency } from '@shared/utils';

import { countryTextFactory } from '@services/DepositService';
import { DepositFundsInfo, DepositOverview } from '@services/DepositService/DepositService.types';

import { CardDepositProvider } from './useCardDepositProviders';

export const useDepositDescription = (assetCode: string, cardDepositProvider?: CardDepositProvider) => {
  const { t } = useTranslation('wallet', { keyPrefix: 'depositInformation' });
  const [stripeMetadata, setStripeMetadata] = useState<GetStripeMetadataResponse>();

  useEffect(() => {
    (async () => {
      if (cardDepositProvider === CardDepositProvider.Stripe) {
        // get the stripe deposit limits and fees via the API if possible
        // note: the assetId param is required for the API call, so we'll pass in -1 and fall through to default values if the asset is not available
        const stripeMetadataResponse = await getStripeLimitsAndFees(assetCode);
        setStripeMetadata(stripeMetadataResponse);
      }
    })();
  }, [cardDepositProvider, assetCode]);

  const depositCreditCardText = useMemo((): DepositOverview => {
    const showStripe = cardDepositProvider === CardDepositProvider.Stripe;

    const stripeOverView: DepositOverview = {
      limits: t('stripe.depositInfo.limits', {
        min: `${formatCurrency(stripeMetadata?.minimumAmount)} ${assetCode}`,
        max: `${formatCurrency(stripeMetadata?.maximumAmount)} ${assetCode}`,
        weeklyMax: `${formatCurrency(stripeMetadata?.maximumWeeklyAmount)} ${assetCode}`,
      }) as any,
      fees: t('stripe.depositInfo.fees', {
        feePercentage: stripeMetadata?.feePercentage,
      }) as any,
      processingTime: {
        description: t('stripe.depositInfo.processingTime') as any,
      },
      extraBankAddressDetails: {
        minimumOrder: Number(stripeMetadata?.minimumAmount),
        maximumOrder: Number(stripeMetadata?.maximumAmount),
      },
      supportedCards: t('stripe.depositInfo.supportedCards'),
      notes: [
        t('stripe.depositInfo.notes.note1') as any,
        t('stripe.depositInfo.notes.note2') as any,
        t('stripe.depositInfo.notes.note3') as any,
      ],
    };

    const nzdBanxa: DepositOverview = {
      successfulDeposits: t('creditCard.nzd.successfulDeposits') as any,
      limits: t('creditCard.nzd.limits', {
        min: `${formatCurrency(MinDailyNZDDeposit.BANXA)} ${assetCode}`,
        max: `${formatCurrency(MaxDailyNZDDeposit.BANXA)} ${assetCode}`,
        monthlyMax: `${formatCurrency(MaxMonthlyNZDDeposit.BANXA)} ${assetCode}`,
      }) as any,
      fees: t('creditCard.nzd.fees') as any,
      processingTime: { description: t('creditCard.nzd.processingTime') as any },
      verification: t('depositFundsInfo.aud.content.verification.description') as any,
      label: t('banxa.nzd.label') as any,
      extraBankAddressDetails: {
        minimumOrder: MinDailyNZDDeposit.BANXA,
        maximumOrder: MaxDailyNZDDeposit.BANXA,
      },
    };

    const audBanxa: DepositOverview = {
      successfulDeposits: t('creditCard.aud.successfulDeposits') as any,
      limits: t('creditCard.aud.limits', {
        min: `${formatCurrency(MinDailyAUDDeposit.BANXA)} ${assetCode}`,
        max: `${formatCurrency(MaxDailyAUDDeposit.BANXA)} ${assetCode}`,
        monthlyMax: `${formatCurrency(MaxMonthlyAUDDeposit.BANXA)} ${assetCode}`,
      }) as any,
      fees: t('creditCard.aud.fees') as any,
      processingTime: { description: t('creditCard.aud.processingTime') as any },
      verification: t('depositFundsInfo.aud.content.verification.description') as any,
      notes: [
        t('banxa.aud.notes.note1') as any,
        t('banxa.aud.notes.note2') as any,
        t('banxa.aud.notes.note3', {
          min: `${formatCurrency(MinDailyAUDDeposit.BANXA)} ${assetCode}`,
          max: `${formatCurrency(MaxDailyAUDDeposit.BANXA)} ${assetCode}`,
          monthlyMax: `${formatCurrency(MaxMonthlyAUDDeposit.BANXA)} ${assetCode}`,
        }) as any,
        t('banxa.aud.notes.note4') as any,
      ],
      label: t('banxa.aud.label') as any,
      extraBankAddressDetails: {
        minimumOrder: MinDailyAUDDeposit.BANXA,
        maximumOrder: MaxDailyAUDDeposit.BANXA,
      },
    };

    return countryTextFactory<DepositOverview>({
      nzd: showStripe ? stripeOverView : nzdBanxa,
      aud: showStripe ? stripeOverView : audBanxa,
      currentCountry: assetCode,
    });
  }, [t, assetCode, cardDepositProvider, stripeMetadata]);

  const depositPayIdTransfer = useMemo(
    () =>
      countryTextFactory<DepositOverview>({
        nzd: {
          warning: t('payId.nzd.warning') as any,
          processingTime: {
            description: t('payId.nzd.processingTime') as any,
            link: Links.help.payID,
            linkDescription: t('payId.nzd.processingTimeLinkDescription') as any,
          },
          limits: t('payId.nzd.limits') as any,
          fees: t('payId.nzd.fees') as any,
          sameNameDeposit: t('bankTransfer.aud.sameNameDeposit') as any,
          verification: t('depositFundsInfo.aud.content.verification.description') as any,
          branchDeposits: t('depositFundsInfo.nzd.content.branchDeposit.description') as any,
        },
        aud: {
          warning: t('payId.aud.warning') as any,
          limits: t('payId.aud.limits') as any,
          fees: t('payId.aud.fees') as any,
          processingTime: {
            description: t('payId.aud.processingTime') as any,
            link: Links.help.payID,
            linkDescription: t('payId.aud.processingTimeLinkDescription') as any,
          },
          sameNameDeposit: t('bankTransfer.aud.sameNameDeposit') as any,
          verification: t('depositFundsInfo.aud.content.verification.description') as any,
          branchDeposits: t('depositFundsInfo.nzd.content.branchDeposit.description') as any,
        },
        currentCountry: assetCode,
      }),
    [t, assetCode],
  );

  const depositBankTransfer = useMemo(
    () =>
      countryTextFactory<DepositOverview>({
        nzd: {
          warning: t('bankTransfer.nzd.warning') as any,
          limits: t('bankTransfer.nzd.limits') as any,
          fees: t('bankTransfer.nzd.fees') as any,
          sameNameDeposit: t('bankTransfer.aud.sameNameDeposit') as any,
          processingTime: { description: t('bankTransfer.nzd.processingTime') as any },
          verification: t('depositFundsInfo.aud.content.verification.description') as any,
          branchDeposits: t('depositFundsInfo.nzd.content.branchDeposit.description') as any,
          addressDetails: [
            {
              title: t('bankTransfer.nzd.addressDetails.accountNumber') as any,
              key: 'address',
            },
            {
              title: t('bankTransfer.nzd.addressDetails.particulars') as any,
              key: 'particulars',
            },
            {
              title: t('bankTransfer.nzd.addressDetails.code') as any,
              key: 'crownReference',
            },
          ],
        },
        aud: {
          warning: t('bankTransfer.aud.warning') as any,
          limits: t('bankTransfer.aud.limits') as any,
          fees: t('bankTransfer.aud.fees') as any,
          sameNameDeposit: t('bankTransfer.aud.sameNameDeposit') as any,
          processingTime: { description: t('bankTransfer.aud.processingTime') as any },
          verification: t('depositFundsInfo.aud.content.verification.description') as any,
          branchDeposits: t('depositFundsInfo.nzd.content.branchDeposit.description') as any,
          addressDetails: [
            {
              title: t('bankTransfer.aud.addressDetails.bsb') as any,
              key: 'bsb',
            },
            {
              title: t('bankTransfer.nzd.addressDetails.accountNumber') as any,
              key: 'address',
            },
          ],
        },
        currentCountry: assetCode,
      }),
    [t, assetCode],
  );

  const depositFundsInfo = useMemo(
    () =>
      countryTextFactory<DepositFundsInfo>({
        aud: {
          title: t('depositFundsInfo.aud.title') as any,
          content: [
            {
              title: t('depositFundsInfo.aud.content.limits.title') as any,
              description: t('depositFundsInfo.aud.content.limits.description') as any,
            },
            {
              title: t('depositFundsInfo.aud.content.fees.title') as any,
              description: t('depositFundsInfo.aud.content.fees.description') as any,
            },
            {
              title: t('depositFundsInfo.aud.content.sameNameDeposit.title') as any,
              description: t('depositFundsInfo.aud.content.sameNameDeposit.description') as any,
            },
            {
              title: t('depositFundsInfo.aud.content.verification.title') as any,
              description: t('depositFundsInfo.aud.content.verification.description') as any,
            },
            {
              title: t('depositFundsInfo.aud.content.branchDeposit.title') as any,
              description: t('depositFundsInfo.aud.content.branchDeposit.description') as any,
            },
          ],
        },
        nzd: {
          title: t('depositFundsInfo.nzd.title') as any,
          content: [
            {
              title: t('depositFundsInfo.nzd.content.limits.title') as any,
              description: t('depositFundsInfo.nzd.content.limits.description') as any,
            },
            {
              title: t('depositFundsInfo.nzd.content.fees.title') as any,
              description: t('depositFundsInfo.nzd.content.fees.description') as any,
            },
            {
              title: t('depositFundsInfo.nzd.content.sameNameDeposit.title') as any,
              description: t('depositFundsInfo.nzd.content.sameNameDeposit.description') as any,
            },
            {
              title: t('depositFundsInfo.nzd.content.verification.title') as any,
              description: t('depositFundsInfo.nzd.content.verification.description') as any,
            },
            {
              title: t('depositFundsInfo.nzd.content.branchDeposit.title') as any,
              description: t('depositFundsInfo.nzd.content.branchDeposit.description') as any,
            },
          ],
        },
        currentCountry: assetCode,
      }),
    [t, assetCode],
  );

  return {
    depositCreditCardText,
    depositPayIdTransfer,
    depositBankTransfer,
    depositFundsInfo,
  };
};

import React, { useEffect } from 'react';

import { TransferTabItem } from '@swyftx/aviary/complex/TransferModal/components/TransferTabs/TransferTabItem/TransferTabItem';
import { Stack } from '@swyftx/react-web-design-system';

import { Asset } from '@shared/api';
import { DepositMethodEnum } from '@shared/enums';

import { useDepositMethods } from '@hooks/Deposit/useDepositMethods';
import { useTheme } from '@hooks/useTheme';

type Props = {
  transferType: DepositMethodEnum;
  asset: Asset;
  setTransferType: (transferType: DepositMethodEnum) => void;
};

export const DepositTabs: React.FC<Props> = ({ transferType, setTransferType, asset }) => {
  const { isLightTheme } = useTheme();
  const depositMethods = useDepositMethods(asset.code);

  const tabs = depositMethods.map((method) => ({
    onClick: () => setTransferType(method.method),
    selected: transferType === method.method,
    label: method.title,
    icon: isLightTheme ? method.icon : method.darkModeIcon || method.icon,
    showIcon: method.showIcon,
  }));

  // Check if the deposit tab is valid
  useEffect(() => {
    if (!depositMethods.find((d) => d.method === transferType)) {
      setTransferType(depositMethods[0].method);
    }
  }, [depositMethods, setTransferType, transferType]);

  return (
    <Stack direction={{ xs: 'column', sm: 'row' }}>
      {tabs.map((tabProps, index) => (
        <TransferTabItem key={`deposit-tab-${index}`} {...tabProps} direction='column' spacing={1} />
      ))}
    </Stack>
  );
};

import { useCallback, useEffect, useMemo, useState } from 'react';

import { Asset, DepositAddressResponse } from '@shared/api';
import { DepositMethodEnum } from '@shared/enums';
import { UserStore } from '@shared/store';

import { CardDepositProvider } from '@hooks/Deposit/useCardDepositProviders';
import { generateBankAddress, getBankAddress } from '@services/DepositService';
import { DepositOverview } from '@services/DepositService/DepositService.types';

import { useDepositDescription } from './useDepositDescription';

type Options = {
  asset: Asset;
  transferType: DepositMethodEnum;
  fetchAddressDetails?: boolean;
};

export const useDeposit = ({ asset, transferType, fetchAddressDetails = true }: Options) => {
  const { userProfile } = UserStore.useUserStore;

  const cardDepositProvider =
    transferType === DepositMethodEnum.BANXA
      ? CardDepositProvider.Banxa
      : transferType === DepositMethodEnum.STRIPE
      ? CardDepositProvider.Stripe
      : undefined;

  const { depositCreditCardText, depositPayIdTransfer, depositBankTransfer } = useDepositDescription(
    asset.code,
    cardDepositProvider,
  );

  const [bankAddress, setBankAddress] = useState<DepositAddressResponse | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  const depositDescription: DepositOverview = useMemo(() => {
    switch (transferType) {
      case DepositMethodEnum.MONOOVA_PAYID:
        return depositPayIdTransfer;
      case DepositMethodEnum.BANXA:
      case DepositMethodEnum.STRIPE:
        return depositCreditCardText;
      case DepositMethodEnum.NZD_BANK_TRANSFER: {
        const depositInformation = depositBankTransfer;
        const accountName = 'Swyftx';
        const reference = userProfile?.name?.last?.substring(0, 12) ?? '';

        depositInformation.extraBankAddressDetails = { reference, accountName };
        return depositInformation;
      }
      case DepositMethodEnum.MONOOVA_DIRECT_CREDIT:
      default: {
        const depositInformation = depositBankTransfer;
        const accountName = 'Swyftx';
        const reference = `${userProfile?.name?.first} ${userProfile?.name?.last}`;

        depositInformation.extraBankAddressDetails = { reference, accountName };
        return depositInformation;
      }
    }
  }, [
    transferType,
    depositPayIdTransfer,
    depositCreditCardText,
    depositBankTransfer,
    userProfile?.name?.last,
    userProfile?.name?.first,
  ]);

  const refetchAddressDetails = useCallback(async () => {
    if (!transferType) return undefined;

    try {
      setLoading(true);
      let bankDetails = await getBankAddress(asset.code, transferType);

      if (!bankDetails) bankDetails = await generateBankAddress(asset.code, transferType);

      setBankAddress(bankDetails);
    } catch (e) {
      setBankAddress(undefined);
    } finally {
      setLoading(false);
    }
  }, [asset.code, transferType]);

  useEffect(() => {
    if (fetchAddressDetails) refetchAddressDetails();
  }, [asset, fetchAddressDetails, refetchAddressDetails]);

  return {
    bankAddress,
    bankAddressDetails: bankAddress?.address_details,
    depositDescription,
    loading,
    refetchAddressDetails,
  };
};

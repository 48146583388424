import React, { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Checkbox } from '@swyftx/aviary/atoms/Checkbox';
import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Notification } from '@swyftx/aviary/atoms/Notification';
import { Body, BodyLink } from '@swyftx/aviary/atoms/Typography';
import { LockedFilled } from '@swyftx/aviary/icons/filled';

import Recaptcha from '@components/molecules/Recaptcha';
import Turnstile from '@components/molecules/Turnstile';

import { StyledForm } from '@global-components/Form/Form.styled';

import env from '@shared/config';
import { StorageKey } from '@shared/storage';

import { zodResolver } from '@hookform/resolvers/zod';
import { observer } from 'mobx-react-lite';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import { ForgotPassword, LoginCreateAccount, LoginTitle } from '../';

type Props = {
  onSignIn: (data: SignInFormValues) => Promise<void>;
};

type SignInFormValues = {
  email: string;
  password: string;
  rememberMe: boolean;
  captchaToken: string;
  error?: string;
};

const SignInFormSchema = z.object({
  email: z.string().min(1).email(),
  password: z.string().min(1),
  rememberMe: z.boolean().optional(),
  captchaToken: env.DISABLE_CAPTCHA ? z.string().optional() : z.string().min(12),
});

export const OfficialSiteWarning: React.FC = observer(() => (
  <Notification
    title=' Always check you are on the official site:'
    severity='info'
    icon={<LockedFilled className='h-20 w-20 text-color-text-accent' />}
  >
    <FlexLayout direction='row' alignItems='center' justifyContent='center' spacing={4}>
      <Body size='small' weight='bold' color='accent'>
        {env.SWYFTX_APP_URL}
      </Body>
    </FlexLayout>
  </Notification>
));

export const SignInForm: React.FC<Props> = (props) => {
  const { onSignIn } = props;
  const [forgottenPassword, setForgottenPassword] = useState<boolean>(false);
  const [captchaRefresh, setCaptchaRefresh] = useState<boolean>(false);
  const { t } = useTranslation('login');
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    setError,
    watch,
  } = useForm<SignInFormValues>({
    defaultValues: {
      email: localStorage.getItem(StorageKey.REMEMBER_ME) ?? '',
      rememberMe: !!localStorage.getItem(StorageKey.REMEMBER_ME),
    },
    resolver: zodResolver(SignInFormSchema),
    mode: 'onChange',
  });

  useLayoutEffect(() => {
    const checkForIntercomBanner = () => {
      const intercomBanner = document.getElementsByName('intercom-banner-frame');

      if (intercomBanner.length > 0) {
        const { body } = document;
        body.style.marginTop = 'unset';
        body.style.maxHeight = 'unset';
      } else {
        setTimeout(checkForIntercomBanner, 100);
      }
    };

    checkForIntercomBanner();
  }, []);

  if (forgottenPassword) {
    return (
      <FlexLayout direction='column' spacing={32}>
        <LoginTitle id='forgotPassword.labels.title' title={t('forgotPassword.title')} />
        <Notification
          severity='warning'
          title='Withdrawing crypto will be disabled'
          className='text-12 text-color-text-primary'
        >
          <Body color='primary' size='small'>
            Please note, this change will disable the ability to withdraw crypto for 48 hours.
          </Body>
        </Notification>
        <ForgotPassword email={watch('email')} backToSignIn={() => setForgottenPassword(false)} />
      </FlexLayout>
    );
  }

  const onSubmit = async (data: SignInFormValues) => {
    await onSignIn(data).catch((error) => {
      try {
        const status = error.error?.response?.status;
        let message = '';

        switch (status) {
          case 429:
            message = t('errors.login.tooManyAttempts');
            break;
          case 401:
            message = t('errors.login.wrong');
            break;
          default:
            message = error.error?.response?.data?.error?.message ?? t('errors.login.none');
            break;
        }

        setError('error', {
          type: 'API',
          message,
        });
      } catch (e) {
        // use the error message built in as a fallback if we fail to parse above
        setError('error', {
          type: 'API',
          message: error.errorMessage,
        });
      }
      setCaptchaRefresh(!captchaRefresh);
    });
  };

  return (
    <FlexLayout direction='column' spacing={16} className='w-full' alignItems='center' justifyContent='start'>
      <FlexLayout direction='column' spacing={16} className='w-full'>
        <LoginTitle id='signIn.labels.title' title='Sign in to Swyftx' />
        <OfficialSiteWarning />
      </FlexLayout>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <FlexLayout direction='column' className='h-full w-full items-center' spacing={16}>
          <Controller
            name='email'
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <FlexLayout direction='column' spacing={4} className='w-full'>
                <Body size='small' weight='bold' color='primary'>
                  Email address
                </Body>
                <Input
                  placeholder='Email address'
                  type='text'
                  onChange={field.onChange}
                  value={field.value}
                  id='signIn.form.email'
                  className='pii-mask relative'
                  autoComplete='off'
                  error={!!field.value?.length && !!errors.email}
                />
              </FlexLayout>
            )}
          />
          <Controller
            name='password'
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <FlexLayout direction='column' spacing={4} className='w-full'>
                <FlexLayout alignItems='center' justifyContent='space-between'>
                  <Body size='small' weight='bold' color='primary'>
                    Password
                  </Body>

                  <BodyLink
                    size='small'
                    color='accent'
                    className='cursor-pointer hover:underline'
                    onClick={() => setForgottenPassword(true)}
                  >
                    Forgot password?
                  </BodyLink>
                </FlexLayout>

                <Input
                  placeholder='Password'
                  type='password'
                  onChange={field.onChange}
                  value={field.value}
                  id='signIn.form.password'
                  className='pii-mask'
                  error={!!field.value?.length && !!errors.password}
                />
              </FlexLayout>
            )}
          />
          <Controller
            name='rememberMe'
            control={control}
            render={({ field }) => (
              <FlexLayout
                direction='row'
                className='w-full items-center'
                spacing={12}
                id='signIn.form.rememberMe.container'
              >
                <Checkbox
                  id='signIn.form.rememberMe'
                  checked={field.value}
                  onCheckedChange={(e) => field.onChange(e.valueOf())}
                  className='h-20 w-20 border-2 border-color-border-main'
                />
                <Body color='primary'>{t('signIn.labels.rememberMe')}</Body>
              </FlexLayout>
            )}
          />
        </FlexLayout>
        <FlexLayout
          direction='column'
          className='mt-36 h-full w-full items-center justify-end'
          id='signIn.form.recaptcha.container'
        >
          <Controller
            name='captchaToken'
            control={control}
            render={({ field }) => (
              <Recaptcha id='signIn.form.recaptcha' submitCaptcha={field.onChange} captchaRefresh={captchaRefresh} />
            )}
          />

          <Turnstile />

          {errors.error && (
            <Body id='sigIn.error' color='error' size='small' className='mb-16'>
              {errors.error.message}
            </Body>
          )}

          <Button
            id='signIn.submit.button'
            size='lg'
            type='submit'
            disabled={!isValid}
            loading={isSubmitting}
            className='w-full'
          >
            {t('signIn.buttonLabels.signIn')}
          </Button>
        </FlexLayout>
      </StyledForm>
      <LoginCreateAccount />
    </FlexLayout>
  );
};

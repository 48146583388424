import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Icon } from '@swyftx/aviary/atoms/Icon';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { DangerFilled } from '@swyftx/aviary/icons/filled';

import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

type Props = {
  onClose: () => void;
};

export const VerificationRequiredForStripeBanner: React.FC<Props> = ({ onClose }) => {
  const { navigate } = useNavigateRoute();
  const { t } = useTranslation('wallet', { keyPrefix: 'depositInformation' });

  return (
    <FlexLayout
      direction='column'
      className='w-full justify-center rounded-12 bg-color-background-surface-secondary p-16'
      spacing={16}
    >
      <FlexLayout direction='column' alignItems='center' spacing={4}>
        <Icon className='h-48 w-48 p-8' icon={<DangerFilled className='h-32 w-32 text-color-icon-warning' />} />
        <Body weight='emphasis' className='justify-end'>
          {t('stripe.verificationRequiredForStripe.title')}
        </Body>
        <Body size='small'>{t('stripe.verificationRequiredForStripe.description')}</Body>
      </FlexLayout>
      <FlexLayout direction='row' className='w-full justify-center'>
        <Button
          onClick={() => {
            navigate(NavigationURLs.ProfileVerification);
            onClose();
          }}
        >
          {t('stripe.verificationRequiredForStripe.buttonLabel')}
        </Button>
      </FlexLayout>
    </FlexLayout>
  );
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Notification, Typography } from '@swyftx/react-web-design-system';

import { Asset } from '@shared/api';
import { getStripeLimitsAndFees, GetStripeMetadataResponse } from '@shared/api/@types/stripeService';
import { UserStore } from '@shared/store';
import { formatCurrency } from '@shared/utils';

import { CardDepositProvider, useCardDepositProviders } from '@hooks/Deposit/useCardDepositProviders';
import { useDepositDescription } from '@hooks/Deposit/useDepositDescription';

import { useMarkets } from 'src/lib/markets/hooks/useMarkets';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { DepositFundsLimitAndFees } from '../DepositFundsLimitAndFees';

interface Props {
  asset: Asset;
}

const CreditCardDepositModalOverview: React.FC<Props> = ({ asset }) => {
  const { isUserVerified } = UserStore.useUserStore;
  const { navigate } = useNavigateRoute();
  const { depositCreditCardText } = useDepositDescription(asset.code);
  const { t } = useTranslation('wallet', { keyPrefix: 'depositInformation' });
  const [stripeMetadata, setStripeMetadata] = useState<GetStripeMetadataResponse>();

  const supportedCardDepositProviders = useCardDepositProviders();

  // this implementation does not cater for both Banxa and Stripe being displayed at the same time
  // the preferred provider is Stripe here will be, if it is not enabled, Banxa is used
  const cardDepositProvider = supportedCardDepositProviders.includes(CardDepositProvider.Stripe)
    ? CardDepositProvider.Stripe
    : CardDepositProvider.Banxa;

  const { getAssetById } = useMarkets();

  const { userCountryCurrency } = UserStore.useUserStore;

  useEffect(() => {
    (async () => {
      const stripeMetadataResponse = await getStripeLimitsAndFees(asset.code);
      setStripeMetadata(stripeMetadataResponse);
    })();
  }, [asset]);

  const openVerifyPage = () => {
    navigate(NavigationURLs.Onboarding);
  };

  return (
    <>
      <Typography color='text.secondary' fontSize={14}>
        {cardDepositProvider === CardDepositProvider.Stripe
          ? t('stripe.cardForm.providerTitle', {
              feePercentage: stripeMetadata?.feePercentage,
            })
          : t('creditCard.steps.providerTitle', {
              value: formatCurrency(
                depositCreditCardText.extraBankAddressDetails?.maximumOrder,
                getAssetById(userCountryCurrency),
              ),
            })}
      </Typography>
      {!isUserVerified() && (
        <Notification
          severity='info'
          title='Verify account to deposit via bank transfer'
          sx={{
            marginTop: 2,
            marginBottom: 2,
            '.MuiAlertTitle-root': {
              marginLeft: 1,
              fontSize: 14,
              fontWeight: 600,
              color: 'primary.main',
            },
          }}
        >
          <Typography marginLeft={1} fontSize={14} fontWeight={400}>
            Your account is not verified. Please verify your account to continue with the deposit.{' '}
          </Typography>
          <Button color='primary' onClick={openVerifyPage}>
            Verify now
          </Button>
        </Notification>
      )}

      {isUserVerified() && (
        <>
          <Notification
            severity='info'
            sx={{
              marginTop: 2,
              padding: 1,
              marginBottom: 2,
              '.MuiAlert-message': { padding: 0, fontSize: '14px', fontWeight: 600, color: 'primary.main' },
            }}
          >
            {depositCreditCardText.processingTime.description}
          </Notification>
          <DepositFundsLimitAndFees
            limits={depositCreditCardText.limits}
            fees={depositCreditCardText.fees}
            successfulDeposits={depositCreditCardText.successfulDeposits}
          />
        </>
      )}
    </>
  );
};

export { CreditCardDepositModalOverview };

export enum MinDailyAUDDeposit {
  BANXA = 91,
  STRIPE = 30,
}

export enum MaxDailyAUDDeposit {
  BANXA = 15000,
  STRIPE = 15000,
}

export enum MaxMonthlyAUDDeposit {
  BANXA = 60000,
}

export enum MaxWeeklyAUDDeposit {
  STRIPE = 15000,
}

export enum MinDailyNZDDeposit {
  BANXA = 95,
  STRIPE = 30,
}

export enum MaxDailyNZDDeposit {
  BANXA = 24300,
  STRIPE = 1000,
}

export enum CardDepositFee {
  BANXA = 1.99,
  STRIPE = 2.99,
}

export enum MaxMonthlyNZDDeposit {
  BANXA = 85000,
}

export enum MaxWeeklyNZDDeposit {
  STRIPE = 15000,
}

import React from 'react';
import { useTranslation } from 'react-i18next';

import { DateOfBirthInput } from '@swyftx/aviary/molecules/DateOfBirthInput/DateOfBirthInput';
import { Input, Typography } from '@swyftx/react-web-design-system';

import { useOnboardingSelector, useOnboardingService } from '@Onboarding/Onboarding.context';
import { VerificationData } from '@Onboarding/types/Onboarding.types';

import { zodResolver } from '@hookform/resolvers/zod';
import { DateTime } from 'luxon';
import { Controller, useForm } from 'react-hook-form';

import { OnboardingStepContainer } from './OnboardingStepContainer';
import { NameAndDateOfBirthSchema } from './schemas/NameAndDateOfBirthSchema';

type FormInputs = Pick<VerificationData, 'givenNames' | 'middleNames' | 'surname' | 'dob'>;

const NameAndDateOfBirthStep: React.FC = () => {
  const { t } = useTranslation('onboarding');
  const onboardingService = useOnboardingService();
  const verificationData = useOnboardingSelector((state) => state.context.verificationData);

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<FormInputs>({
    mode: 'onChange',
    defaultValues: {
      givenNames: verificationData.givenNames ?? '',
      middleNames: verificationData.middleNames ?? '',
      surname: verificationData.surname ?? '',
      dob: verificationData.dob ?? null,
    },
    resolver: zodResolver(NameAndDateOfBirthSchema, undefined, { rawValues: true }),
  });

  const onSubmit = (data: FormInputs) => {
    onboardingService.send({
      type: 'NEXT',
      data: isDirty
        ? {
            ...data,
            // @ts-ignore need to do this hack to get around strange luxon date transform issue with hook form
            dob: DateTime.fromObject(data.dob?.c),
          }
        : undefined,
    });
  };

  return (
    <OnboardingStepContainer onSubmit={handleSubmit(onSubmit)} submitDisabled={!isValid}>
      <Typography color='text.secondary' marginBottom={4}>
        {t('steps.setupProfile.nameAndDob.description')}
      </Typography>

      <Controller
        name='givenNames'
        control={control}
        rules={{
          required: t('fields.givenNames.error.required') as string,
        }}
        render={({ field }) => (
          <Input
            id='onboarding.givenNames'
            PII
            label={t('fields.givenNames.label')}
            onChange={field.onChange}
            value={field.value}
            error={!!errors.givenNames}
            helperText={errors.givenNames?.message}
            sx={{ marginBottom: 3 }}
            required
          />
        )}
      />

      <Controller
        name='middleNames'
        control={control}
        rules={{
          minLength: {
            value: 2,
            message: t('fields.middleNames.error.tooShort') as string,
          },
          maxLength: {
            value: 32,
            message: t('fields.middleNames.error.tooLong') as string,
          },
        }}
        render={({ field }) => (
          <Input
            id='onboarding.middleNames'
            PII
            label={t('fields.middleNames.label')}
            onChange={field.onChange}
            value={field.value}
            error={!!errors.middleNames}
            helperText={errors.middleNames?.message}
            sx={{ marginBottom: 3 }}
          />
        )}
      />

      <Controller
        name='surname'
        control={control}
        rules={{
          required: t('fields.surname.error.required') as string,
          minLength: {
            value: 2,
            message: t('fields.middleNames.error.tooShort') as string,
          },
          maxLength: {
            value: 32,
            message: t('fields.middleNames.error.tooLong') as string,
          },
        }}
        render={({ field }) => (
          <Input
            id='onboarding.surname'
            PII
            label={t('fields.surname.label')}
            onChange={field.onChange}
            value={field.value}
            error={!!errors.surname}
            helperText={errors.surname?.message}
            sx={{ marginBottom: 3 }}
            required
          />
        )}
      />

      <Controller
        name='dob'
        control={control}
        rules={{ required: t('fields.dob.error') as string }}
        render={({ field }) => (
          <>
            <Typography
              fontWeight={600}
              color='text.primary'
              fontSize={14}
              marginBottom='.5rem'
              fontFamily='Public Sans'
            >
              {t('fields.dob.label')}
              <span style={{ color: '#EF2D4E' }}>*</span>
            </Typography>

            <DateOfBirthInput
              // @ts-ignore need to do this hack to get around strange luxon date transform issue with hook form
              value={field.value ? DateTime.fromObject(field.value?.c) : undefined}
              onChange={field.onChange}
              error={errors.dob?.message}
            />
          </>
        )}
      />
    </OnboardingStepContainer>
  );
};

export { NameAndDateOfBirthStep };

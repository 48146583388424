import env from '@shared/config';

import { Turnstile as ReactTurnstile } from '@marsidev/react-turnstile';

export const Turnstile: React.FC = () => {
  if (!env.TURNSTILE_SITE_KEY) return null;

  // TODO: implement callbacks if/when adding server-side validation on back-end:
  // https://developers.cloudflare.com/turnstile/get-started/server-side-validation/

  const handleSuccess = () => {};

  const handleExpire = () => {};

  const handleError = () => {};

  return (
    <ReactTurnstile
      siteKey={env.TURNSTILE_SITE_KEY}
      onSuccess={handleSuccess}
      onExpire={handleExpire}
      onError={handleError}
    />
  );
};

import { DateTime } from 'luxon';
import { z } from 'zod';

export const NameAndDateOfBirthSchema = z.object({
  givenNames: z
    .string({
      required_error: 'First name field is required',
    })
    .min(2, 'Name is too short. Please contact support if your name is one character long')
    .max(32, 'Name is too long - max 32 characters. Please contact support if your name is longer'),
  surname: z
    .string({ required_error: 'Last name field is required' })
    .min(2, 'Name is too short. Please contact support if your name is one character long')
    .max(32, 'Name is too long - max 32 characters. Please contact support if your name is longer'),
  middleNames: z
    .string()
    .min(2, 'Name is too short. Please contact support if your name is one character long')
    .max(32, 'Name is too long - max 32 characters. Please contact support if your name is longer')
    .or(z.literal(''))
    .optional(),
  dob: z.any().superRefine((dob, ctx) => {
    const date = dob as DateTime;
    if (!date.isValid) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Invalid date of birth. Please enter a valid date.',
      });
    } else {
      if (date.year < 1900) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please enter a date of birth after the year 1900',
        });
      } else if (date > DateTime.now().minus({ years: 18 })) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'You must be at least 18 years old',
        });
      }
    }
  }),
});

import React from 'react';

import { Input } from '@swyftx/aviary/atoms/Input';
import { InputProps } from '@swyftx/aviary/atoms/Input/Input.styles';

type Props = {
  value?: string;
  placeholder?: string;
  error?: boolean;
  maxLength?: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: InputProps['onKeyDown'];
};

const DateOfBirthInputField = React.forwardRef<HTMLInputElement, Props>(
  ({ value, placeholder, error, maxLength, onChange, onKeyDown }, ref) => (
    <Input
      ref={ref}
      value={value}
      placeholder={placeholder}
      error={error}
      onChange={onChange}
      onKeyDown={onKeyDown}
      min={1}
      maxLength={maxLength}
      type='number'
    />
  ),
);

DateOfBirthInputField.displayName = 'DateOfBirthInputField';

export { DateOfBirthInputField };
